import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    Empty,
    Form,
    Input,
    Radio,
    Row,
    Badge,
    Select,
    Spin,
    Tag,
    Tooltip,
    Modal,
    Popconfirm,
    Space,
    Flex,
    Table,
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    SendOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import ShortUniqueId from "short-unique-id";
import QRCode from "qrcode";
import { RealmContext } from "../../context/realmProvider";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import {
    insertAssessments,
    selectAssessments,
    selectTags,
    selectUser,
    selectUsers,
    updateAssessments,
} from "../../redux/assessmentsSlice";
import {
    inviteFormat,
    ObjectId,
    validateEmail,
    _,
    inviteTemplate,
} from "../../utils";

const generateQR = async (text) => {
    try {
        console.log(await QRCode.toDataURL(text));
    } catch (err) {
        console.error(err);
    }
};
const uid = new ShortUniqueId({
    dictionary: "alphanum_lower",
    length: 5,
});
const tagRender = (props) => {
    const { label, cc, value, onEditTag, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        console.log(event);
        event.preventDefault();
        //event.stopPropagation();
    };
    return (
        <>
            <Tag
                closable={closable}
                onClose={onClose}
                style={{
                    marginInlineEnd: 4,
                }}
            >
                {label}{" "}
                {/* <EditOutlined
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        onEditTag ? onEditTag() : console.log("edit tag");
                    }}
                    style={{ cursor: "pointer" }}
                /> */}
            </Tag>
        </>
    );
};

function QuickAddAssessment(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const assessments = useSelector(selectAssessments);
    const [assessmentState, setAssessmentState] = useState();
    const [assessmentType, setAssessmentType] = useState([]);
    const { mongo, user, app } = useContext(RealmContext);
    const [advanced, setAdvanced] = useState(null);
    const [tags, settags] = useState([]);
    const currentUser = useSelector(selectUser);
    const users = useSelector(selectUsers);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isAdmin = currentUser?.group == "Admin";
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    //console.log(tagsState);

    const dispatch = useDispatch();
    useEffect(() => {
        var _tags = [];
        assessments.forEach((a) => {
            if (a.tags) _tags = _.union(_tags, a.tags);
        });
        _tags = _tags.map((t) => {
            return { value: t, label: t };
        });
        settags(_tags);
    }, [assessments]);
    const handleChange = (changedValues, allValues) => {
        //console.log({ changedValues, allValues });
        var compiled = _.template(inviteTemplate);
        // console.log(
        //   compiled({
        //     gender: allValues.gender,
        //     firstname: allValues.firstname,
        //     lastname: allValues.lastname,
        //     links: "",
        //     help: "",
        //   }),
        //   allValues
        // );

        if (allValues.type.length > 0) {
            var types = [];
            allValues.type.forEach((type) => {
                types.push({
                    type,
                    short: uid(),
                });
            });
            setAssessmentType(types);
        }
        setAssessmentState(inviteFormat({ ...allValues, types, user }));
        // setAssessmentState(
        //   compiled({
        //     gender: changedValues.gender,
        //     firstname: changedValues.firstname,
        //     lastname: changedValues.lastname,
        //     links: "",
        //     help: "",
        //   })
        // );
    };
    const handleChangeTags = (arrValues) => {
        console.log(arrValues);
        // const values = value.replaceAll(" ", "");
        // if (!values) return true;
        // const arrValues = values.split(",");

        for (let i = 0; i < arrValues.length; i++) {
            //console.log(form.getFieldValue("emailCC"));
            if (validateEmail(arrValues[i])) {
                //console.log("add email: ", arrValues[i]);
                form.setFieldsValue({
                    emailCC:
                        form.getFieldValue("emailCC") &&
                        !form.getFieldValue("emailCC").includes(arrValues[i])
                            ? form.getFieldValue("emailCC") +
                              ", " +
                              arrValues[i]
                            : arrValues[i],
                });
            }
        }
        console.log("tags", form.getFieldValue("tags"));
        return true;
    };
    const handleFinish = async (values) => {
        if (currentUser.parentID) {
            const parent = users.find((u) => u.userID == currentUser.parentID);
            // console.log("parentID", currentUser.parentID);
            // console.log("parent", parent._credit);
            // console.log(
            //     "length",
            //     values.type?.length,
            //     values.type?.length > 0 && values.type.length > parent._credit
            // );
            if (
                values.type?.length > 0 &&
                values.type.length > parent._credit
            ) {
                Modal.warning({
                    title: "Lỗi thanh toán!",
                    content: "Số dư không đủ!",
                });
                await user.functions.sendmail({
                    from: "Assessments 24x7 <info@a247.co>",
                    to: "vunh2301@gmail.com",
                    subject: "Lỗi cấp link Assessments 24x7",
                    "o:tag": "a247.co",
                    html: `<p>Không thể cấp link cho <br/><pre>${JSON.stringify(
                        { values, currentUser },
                        null,
                        2
                    )}</pre> <br/>vì số dư không đủ!</p>`,
                });
                return;
            }
        }

        if (
            currentUser._credit != null &&
            values.type?.length > 0 &&
            values.type.length > currentUser._credit
        ) {
            Modal.warning({
                title: "Lỗi thanh toán!",
                content: "Số dư không đủ!",
            });
            return;
        }

        if (
            values.type?.length > 0 &&
            values.type.length > currentUser.credit
        ) {
            Modal.warning({
                title: "Thông báo",
                content: "Số dư không đủ!",
            });
            return;
        }

        setLoading(true);
        values = { ...values, email: values.email.toLowerCase() };
        const entities = [];
        for (const i in assessmentType) {
            entities.push({
                ...values,
                type: assessmentType[i].type,
                owner: user.id,
                short: assessmentType[i].short,
                status: "Created",
                report: {
                    current: 0,
                },
                created: new Date(),
                updated: new Date(),
                ip: mongo.ip,
                host:
                    window.location.hostname == "app.ns24.io" ? "ns24" : "app",
            });
        }

        //console.log(entities);
        const { payload } = await dispatch(
            insertAssessments({ mongo, entities })
        );
        //console.log(payload);

        //console.log("assessmentState", assessmentState);

        if (mongo.ip)
            await dispatch(
                updateAssessments({
                    mongo,
                    ids: payload.map(({ _id }) => ObjectId(_id)),
                    update: { status: "Sented", updated: new Date() },
                })
            );
        if (mongo.ip)
            await user.functions.sendmail({
                from: "Assessments 24x7 <info@a247.co>",
                to: values.email,
                cc: values.emailCC,
                bcc: "vunh2301@gmail.com",
                subject: "Bài đánh giá Assessments 24x7",
                "o:tag": "a247.co",
                html: assessmentState,
            });
        form.resetFields();
        setAssessmentState(null);
        setLoading(false);
    };
    return (
        <Spin spinning={loading}>
            <Row gutter={20}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h3>Thông tin khách hàng:</h3>
                    <Form
                        form={form}
                        layout="vertical"
                        name="quickAddAssessment"
                        onFinish={handleFinish}
                        onValuesChange={handleChange}
                        initialValues={{
                            email: "",
                            type: [],
                            language: "Vietnamese",
                            gender: "Anh/Chị",
                            emailCC: "",
                        }}
                    >
                        <Row gutter={20}>
                            <Col span={16}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            required: true,
                                            message:
                                                "Vui lòng nhập địa chỉ email!",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Địa chỉ Email"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="gender"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        options={[
                                            {
                                                value: "Anh/Chị",
                                                label: "Anh/Chị",
                                            },
                                            {
                                                value: "Anh",
                                                label: "Anh",
                                            },
                                            {
                                                value: "Chị",
                                                label: "Chị",
                                            },
                                            {
                                                value: "Bạn",
                                                label: "Bạn",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={16}>
                                <Form.Item
                                    name="firstname"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Vui lòng nhập Họ và Tên lót!",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Họ và Tên lót"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="lastname"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập Tên!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Tên" allowClear />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col span={16}>
                                <Form.Item
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Vui lòng chọn bài Đánh giá!",
                                        },
                                    ]}
                                >
                                    <Checkbox.Group>
                                        <Row>
                                            <Col span={12}>
                                                <Checkbox
                                                    value="DISC"
                                                    style={{
                                                        lineHeight: "32px",
                                                    }}
                                                >
                                                    DISC{" "}
                                                    <Tooltip
                                                        title={`1 bài test bằng ${
                                                            currentUser
                                                                .creditRate?.[
                                                                "DISC"
                                                            ] || 1
                                                        } Credit`}
                                                    >
                                                        <small className="text-red-600">
                                                            (-
                                                            {currentUser
                                                                .creditRate?.[
                                                                "DISC"
                                                            ] || 1}{" "}
                                                            Credit)
                                                        </small>
                                                    </Tooltip>
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    value="Motivators"
                                                    style={{
                                                        lineHeight: "32px",
                                                    }}
                                                >
                                                    Motivators{" "}
                                                    <Tooltip
                                                        title={`1 bài test bằng ${
                                                            currentUser
                                                                .creditRate?.[
                                                                "Motivators"
                                                            ] || 1
                                                        } Credit`}
                                                    >
                                                        <small className="text-red-600">
                                                            (-
                                                            {currentUser
                                                                .creditRate?.[
                                                                "Motivators"
                                                            ] || 1}{" "}
                                                            Credit)
                                                        </small>
                                                    </Tooltip>
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    value="Sale IQ Plus"
                                                    style={{
                                                        lineHeight: "32px",
                                                    }}
                                                >
                                                    Sale IQ Plus{" "}
                                                    <Tooltip
                                                        title={`1 bài test bằng ${
                                                            currentUser
                                                                .creditRate?.[
                                                                "Sale IQ Plus"
                                                            ] || 1
                                                        } Credit`}
                                                    >
                                                        <small className="text-red-600">
                                                            (-
                                                            {currentUser
                                                                .creditRate?.[
                                                                "Sale IQ Plus"
                                                            ] || 1}{" "}
                                                            Credit)
                                                        </small>
                                                    </Tooltip>
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    value="EIQ 2"
                                                    style={{
                                                        lineHeight: "32px",
                                                    }}
                                                >
                                                    EIQ 2{" "}
                                                    <Tooltip
                                                        title={`1 bài test bằng ${
                                                            currentUser
                                                                .creditRate?.[
                                                                "EIQ 2"
                                                            ] || 1
                                                        } Credit`}
                                                    >
                                                        <small className="text-red-600">
                                                            (-
                                                            {currentUser
                                                                .creditRate?.[
                                                                "EIQ 2"
                                                            ] || 1}{" "}
                                                            Credit)
                                                        </small>
                                                    </Tooltip>
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    value="Learning Styles"
                                                    style={{
                                                        lineHeight: "32px",
                                                    }}
                                                >
                                                    Learning Styles{" "}
                                                    <Tooltip
                                                        title={`1 bài test bằng ${
                                                            currentUser
                                                                .creditRate?.[
                                                                "Learning Styles"
                                                            ] || 1
                                                        } Credit`}
                                                    >
                                                        <small className="text-red-600">
                                                            (-
                                                            {currentUser
                                                                .creditRate?.[
                                                                "Learning Styles"
                                                            ] || 1}{" "}
                                                            Credit)
                                                        </small>
                                                    </Tooltip>
                                                </Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox
                                                    value="Kids DISC"
                                                    style={{
                                                        lineHeight: "32px",
                                                    }}
                                                >
                                                    Kids DISC{" "}
                                                    <Tooltip
                                                        title={`1 bài test bằng ${
                                                            currentUser
                                                                .creditRate?.[
                                                                "Kids DISC"
                                                            ] || 1
                                                        } Credit`}
                                                    >
                                                        <small className="text-red-600">
                                                            (-
                                                            {currentUser
                                                                .creditRate?.[
                                                                "Kids DISC"
                                                            ] || 1}{" "}
                                                            Credit)
                                                        </small>
                                                    </Tooltip>
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="phone">
                                    <Input
                                        style={{ width: "100%" }}
                                        placeholder="Số điện thoại"
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item name="language">
                                    <Radio.Group>
                                        <Radio value="Vietnamese">
                                            Vietnamese
                                        </Radio>
                                        <Radio value="English">English</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    name="emailCC"
                                    rules={[
                                        {
                                            validator: async (rule, value) => {
                                                const values = value.replaceAll(
                                                    " ",
                                                    ""
                                                );
                                                if (!values) return true;
                                                const arrValues =
                                                    values.split(",");

                                                for (
                                                    let i = 0;
                                                    i < arrValues.length;
                                                    i++
                                                ) {
                                                    if (
                                                        !validateEmail(
                                                            arrValues[i]
                                                        )
                                                    )
                                                        throw new Error(
                                                            "Something wrong!"
                                                        );
                                                }
                                                return true;
                                            },
                                            message:
                                                "Vui lòng nhập địa chỉ email!",
                                        },
                                        {
                                            validator: async (rule, value) => {
                                                const values = value.replaceAll(
                                                    " ",
                                                    ""
                                                );
                                                if (!values) return true;
                                                const arrValues =
                                                    values.split(",");

                                                for (
                                                    let i = 0;
                                                    i < arrValues.length;
                                                    i++
                                                ) {
                                                    console.log(
                                                        arrValues[i],
                                                        form.getFieldValue(
                                                            "email"
                                                        )
                                                    );
                                                    if (
                                                        arrValues[i] ==
                                                        form.getFieldValue(
                                                            "email"
                                                        )
                                                    )
                                                        throw new Error(
                                                            "Something wrong!"
                                                        );
                                                }
                                                return true;
                                            },
                                            message:
                                                "Email CC không được trùng với email khách hàng!",
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        type="textarea"
                                        autoSize
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Email CC:(Cách nhau bằng dấu ,)"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="tags"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        placeholder="Tags: Nhập và nhấn Enter để tạo tag mới"
                                        mode="tags"
                                        style={{
                                            width: "100%",
                                        }}
                                        options={tags}
                                        maxTagCount="responsive"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Collapse
                            activeKey={advanced}
                            ghost
                            items={[
                                {
                                    key: "advanced",
                                    showArrow: false,
                                    header: null,
                                    forceRender: true,
                                    children: (
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="emailCC"
                                                    rules={[
                                                        {
                                                            validator: async (
                                                                rule,
                                                                value
                                                            ) => {
                                                                const values =
                                                                    value.replaceAll(
                                                                        " ",
                                                                        ""
                                                                    );
                                                                if (!values)
                                                                    return true;
                                                                const arrValues =
                                                                    values.split(
                                                                        ","
                                                                    );

                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    arrValues.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        !validateEmail(
                                                                            arrValues[
                                                                                i
                                                                            ]
                                                                        )
                                                                    )
                                                                        throw new Error(
                                                                            "Something wrong!"
                                                                        );
                                                                }
                                                                return true;
                                                            },
                                                            message:
                                                                "Vui lòng nhập địa chỉ email!",
                                                        },
                                                        {
                                                            validator: async (
                                                                rule,
                                                                value
                                                            ) => {
                                                                const values =
                                                                    value.replaceAll(
                                                                        " ",
                                                                        ""
                                                                    );
                                                                if (!values)
                                                                    return true;
                                                                const arrValues =
                                                                    values.split(
                                                                        ","
                                                                    );

                                                                for (
                                                                    let i = 0;
                                                                    i <
                                                                    arrValues.length;
                                                                    i++
                                                                ) {
                                                                    console.log(
                                                                        arrValues[
                                                                            i
                                                                        ],
                                                                        form.getFieldValue(
                                                                            "email"
                                                                        )
                                                                    );
                                                                    if (
                                                                        arrValues[
                                                                            i
                                                                        ] ==
                                                                        form.getFieldValue(
                                                                            "email"
                                                                        )
                                                                    )
                                                                        throw new Error(
                                                                            "Something wrong!"
                                                                        );
                                                                }
                                                                return true;
                                                            },
                                                            message:
                                                                "Email CC không được trùng với email khách hàng!",
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        type="textarea"
                                                        autoSize
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Email CC:(Cách nhau bằng dấu ,)"
                                                        allowClear
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="tags">
                                                    <Select
                                                        allowClear
                                                        placeholder="Tags: Nhập và nhấn Enter để tạo tag mới"
                                                        mode="tags"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                        options={tags}
                                                        maxTagCount="responsive"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ),
                                },
                            ]}
                        ></Collapse>
                         <Collapse activeKey={advanced} ghost>
              <Collapse.Panel
                showArrow={false}
                header={null}
                key='advanced'
                forceRender>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name='emailCC'
                      rules={[
                        {
                          //type: "email",
                          validator: async (rule, value) => {
                            const values = value.replaceAll(" ", "");
                            if (!values) return true;
                            const arrValues = values.split(",");
                            console.log(arrValues);
                            for (let i = 0; i < arrValues.length; i++) {
                              if (!validateEmail(arrValues[i]))
                                throw new Error("Something wrong!");
                            }
                            return true;
                          },
                          message: "Vui lòng nhập địa chỉ email!",
                        },
                      ]}>
                      <Input
                        style={{ width: "100%" }}
                        placeholder='Địa chỉ Email CC'
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='tags'>
                      <Select
                        allowClear
                        placeholder='Tags: Nhập và nhấn Enter để tạo tag mới'
                        mode='tags'
                        style={{ width: "100%" }}
                        options={tags}
                        maxTagCount='responsive'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse> */}
                        <Form.Item>
                            {/* <Button
                                icon={<SettingOutlined />}
                                onClick={() => {
                                    if (advanced === null) {
                                        setAdvanced(["advanced"]);
                                    } else {
                                        setAdvanced(null);
                                    }
                                }}
                            >
                                Mở rộng
                            </Button> */}
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                htmlType="submit"
                                icon={<SendOutlined />}
                            >
                                Gửi bài đánh giá
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h3>
                        Nội dung email:{" "}
                        {/* <Select
              defaultValue='default'
              style={{
                width: 120,
              }}
              onChange={() => {}}
              options={[
                {
                  value: "default",
                  label: "Mặc định",
                },
                {
                  value: "random",
                  label: "Mẫu 1",
                },
              ]}
            /> */}
                    </h3>
                    <Card>
                        {assessmentState ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: assessmentState,
                                }}
                            />
                        ) : (
                            // <ReactQuill
                            //   theme="bubble"
                            //   value={assessmentState}
                            //   onChange={setAssessmentState}
                            // />
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                        {/* <ReactQuill
              theme='bubble'
              value={assessmentState}
              onChange={setAssessmentState}
            /> */}
                    </Card>
                </Col>
            </Row>
        </Spin>
    );
}

export default QuickAddAssessment;
