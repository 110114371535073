import {
    Avatar,
    Badge,
    Button,
    Card,
    DatePicker,
    Form,
    Input,
    notification,
    // Pagination,
    Popconfirm,
    Popover,
    Progress,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
} from "antd";
import {
    CloudDownloadOutlined,
    LinkOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    dateRangeAssessments,
    deleteAssessment,
    filterAssessments,
    searchAssessments,
    selectAssessments,
    typeAssessments,
    selectFilter,
    selectFilterAssessments,
    filterUserAssessments,
    selectSearch,
    selectStatus,
    selectType,
    statusAssessments,
    selectUser,
    selectUsers,
    filterUsers,
} from "../../redux/assessmentsSlice";
import "moment/locale/vi";
import { RealmContext } from "../../context/realmProvider";
import { _ } from "../../utils";
// import Search from "antd/es/transfer/search";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import Transfer from "./Transfer";
import Move from "./Move";
import ReFill from "./ReFill";
import Remove from "./Remove";
const emailUpdate = (type, short, gender, firstname, lastname) => {
    return `

    <p>
      <b>${gender ? gender : "Anh/Chị"} ${firstname} ${lastname}</b>
      thân mến:
    </p>
    <p>
      <b>Assessments 24x7</b> cảm ơn <b>${
          gender ? gender : "Anh/Chị"
      } ${lastname}</b> đã đăng ký tham gia đánh giá
      của chúng tôi.
    </p>
    <p>
      <b>Từ 25/9/2024</b> để bắt đầu làm bài đánh giá hoặc xem lại kết quả bài đánh giá <b>${
          gender ? gender : "Anh/Chị"
      } ${lastname}</b> vui
        lòng click vào liên kết bên dưới.
    </p>
    <ul>
        <li>
            <b>${type}: </b>
            <a href="https://a247.co/${short}" target="_blank">
                https://a247.co/${short}
            </a>
        </li>
    </ul>
    <p>Trân trọng!</p>
    `;
};
const { RangePicker } = DatePicker;
function Assessments(props) {
    const { mongo } = useContext(RealmContext);
    const assessments = useSelector(selectAssessments);
    const computeAssessments = useSelector(selectFilterAssessments);
    const search = useSelector(selectSearch);
    const selectTag = useSelector(selectFilter);
    const filStatus = useSelector(selectStatus);
    const filType = useSelector(selectType);
    const filUsers = useSelector(filterUsers);
    const currentUser = useSelector(selectUser);
    const users = useSelector(selectUsers);
    const isAdmin = currentUser?.group == "Admin";
    const { user } = useContext(RealmContext);
    const [api, contextHolder] = notification.useNotification();
    const [tags, settags] = useState([]);
    const [objTags, setObjTags] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        var _tags = [];
        var tagsArrObj = [];
        assessments.forEach((a) => {
            //console.log(a);
            if (a.tags) _tags = _.union(_tags, a.tags);
            // Xu ly Tags
            // if (a.tags) {
            //     a.tags.forEach((t) => {
            //         //console.log(t, !tagsArrObj.some((item) => item.value == t));
            //         // if (tagsArrObj.some((item) => item.value == t)) {
            //         //     console.log(
            //         //         t,
            //         //         tagsArrObj.find((item) => item.value == t).owner ==
            //         //             a.owner
            //         //     );
            //         // }
            //         if (
            //             !tagsArrObj.some(
            //                 (item) => item.value == t + "_" + a.owner
            //             )
            //         ) {
            //             tagsArrObj.push({
            //                 value: t + "_" + a.owner,
            //                 label: t,
            //                 owner: a.owner,
            //                 color: "default",
            //             });
            //             // const tag = {
            //             //     value: t + "," + a.owner,
            //             //     label: t,
            //             //     owner: a.owner,
            //             //     color: "#fafafa",
            //             // };
            //             // mongo.db("a247").collection("tags").insertOne(tag);
            //             //console.log(tag);
            //         }
            //     });
            //     //tagsArrObj = _.unionBy(tagsArrObj, a.tags, "value");
            // }
        });
        //console.log(tagsArrObj);
        // tagsArrObj.forEach((t) => {
        //     mongo.db("a247").collection("tags").insertOne(t);
        // });
        _tags = _tags.map((t) => {
            return { value: t, label: t };
        });
        settags(_tags);
        setObjTags(tagsArrObj);
    }, [assessments]);

    const handleDelete = (id) => {
        dispatch(deleteAssessment({ mongo, id }));
    };
    const statusColor = (status) => {
        switch (status) {
            case "Created":
                return "#999";
            case "Sented":
                return "#2db7f5";
            case "Opened":
            case "Doing":
                return "#108ee9";
            case "Completed":
                return "#87d068";
            case "Expired":
                return "#cd201f";
            case "Failed":
                return "#ff8000";
        }
        return "#2db7f5";
    };
    const handleSearch = (e) => {
        dispatch(searchAssessments(e.target.value));
        //console.log(computeAssessments);
    };
    const handleFilter = (value) => {
        dispatch(filterAssessments(value));
    };
    const handleUserChange = (value) => {
        dispatch(filterUserAssessments(value));
    };
    const handleDateRange = (values) => {
        dispatch(dateRangeAssessments(values));
    };
    const handleStatus = (value) => {
        //console.log(value);
        dispatch(statusAssessments(value));
    };
    const handleType = (value) => {
        dispatch(typeAssessments(value));
    };
    const handleSelectTag = (tag) => {
        if (!selectTag.includes(tag)) {
            dispatch(filterAssessments([...selectTag, tag]));
        }
    };
    const handleSelectStatus = (value) => {
        if (!filStatus.includes(value)) {
            dispatch(statusAssessments([...filStatus, value]));
        }
    };
    const handleSendmail = async ({
        email,
        type,
        short,
        gender,
        firstname,
        lastname,
    }) => {
        console.log({ email, type, short, gender, firstname, lastname });
        await user.functions.sendmail({
            from: "Assessments 24x7 <info@a247.co>",
            to: email,
            bcc: "vunh2301@gmail.com",
            subject: "Assessments 24x7 - Cập nhật tháng 9/2024",
            "o:tag": "a247.co",
            html: emailUpdate(type, short, gender, firstname, lastname),
        });
        api["success"]({
            message: "Gửi thành công",
            description: email,
        });
    };
    //return <SelectTags />;
    return (
        <Card>
            {contextHolder}
            {/* <Button
                onClick={() => {
                    objTags.forEach((t) => {
                        mongo.db("a247").collection("tags").insertOne(t);
                    });
                }}
            >
                Insert Tags
            </Button> */}
            <Input.Search
                style={{
                    width: 250,
                    marginRight: 15,
                    marginBottom: 20,
                }}
                defaultValue={search}
                placeholder="Nhập từ khoá tìm kiếm"
                onChange={handleSearch}
                enterButton
            />

            <Select
                allowClear
                value={filType}
                placeholder="Lọc theo Bài test"
                style={{
                    width: 200,
                    marginRight: 15,
                    marginBottom: 20,
                }}
                onChange={handleType}
                options={[
                    {
                        value: "DISC",
                        label: "DISC",
                    },
                    {
                        value: "Motivators",
                        label: "Motivators",
                    },
                    {
                        value: "Sale IQ Plus",
                        label: "Sale IQ Plus",
                    },
                    {
                        value: "EIQ 2",
                        label: "EIQ 2",
                    },
                    {
                        value: "Learning Styles",
                        label: "Learning Styles",
                    },
                    {
                        value: "Kids DISC",
                        label: "Kids DISC",
                    },
                ]}
            />
            <Select
                allowClear
                placeholder="Lọc theo Tags"
                mode="multiple"
                value={selectTag}
                style={{ width: 200, marginRight: 15, marginBottom: 20 }}
                maxCount={5}
                onChange={handleFilter}
                options={tags}
                maxTagCount="responsive"
            />
            <Select
                allowClear
                value={filStatus}
                placeholder="Lọc theo Trạng thái"
                mode="multiple"
                style={{
                    width: 200,
                    marginRight: 15,
                    marginBottom: 20,
                }}
                onChange={handleStatus}
                options={[
                    {
                        value: "Created",
                        label: "Created",
                    },
                    {
                        value: "Sented",
                        label: "Sent",
                    },
                    {
                        value: "Failed",
                        label: "Failed",
                    },
                    {
                        value: "Opened",
                        label: "Opened",
                    },
                    {
                        value: "Doing",
                        label: "Doing",
                    },
                    {
                        value: "Completed",
                        label: "Completed",
                    },
                    {
                        value: "Expired",
                        label: "Expired",
                    },
                ]}
            />

            <DatePicker.RangePicker
                locale={locale}
                style={{ width: 300, marginRight: 15, marginBottom: 20 }}
                format="DD MMM YYYY"
                presets={[
                    {
                        label: "7 Ngày trước",
                        value: [dayjs().add(-7, "d"), dayjs()],
                    },
                    {
                        label: "14 Ngày trước",
                        value: [dayjs().add(-14, "d"), dayjs()],
                    },
                    {
                        label: "30 Ngày trước",
                        value: [dayjs().add(-30, "d"), dayjs()],
                    },
                    {
                        label: "90 Ngày trước",
                        value: [dayjs().add(-90, "d"), dayjs()],
                    },
                    {
                        label: "Tháng này",
                        value: [
                            dayjs(`${dayjs().format("YYYY-MM-")}01`),
                            dayjs(
                                `${dayjs().format(
                                    "YYYY-MM-"
                                )}${dayjs().daysInMonth()}`
                            ),
                        ],
                    },
                    {
                        label: "Tháng trước",
                        value: [
                            dayjs(`${dayjs().format("YYYY-MM-")}01`).subtract(
                                1,
                                "month"
                            ),
                            dayjs(
                                `${dayjs().format(
                                    "YYYY-MM-"
                                )}${dayjs().daysInMonth()}`
                            ).subtract(1, "month"),
                        ],
                    },
                ]}
                onChange={handleDateRange}
            />

            {isAdmin && (
                <Select
                    allowClear
                    placeholder="Lọc theo User"
                    mode="multiple"
                    value={filUsers}
                    style={{ width: 200, marginRight: 15, marginBottom: 20 }}
                    onChange={handleUserChange}
                    options={users?.map((u) => ({
                        value: u.userID,
                        label: u.fullname,
                    }))}
                    maxTagCount="responsive"
                />
            )}

            <Table
                id="body-view"
                rowKey="_id"
                columns={[
                    {
                        title: "Bài đánh giá",
                        dataIndex: "type",
                        width: "300px",
                        render: (_, entity) => {
                            var avatar = null;
                            switch (entity.type) {
                                case "DISC": {
                                    avatar =
                                        "https://actioncoachassessments.com/UploadArea/269/badge6.jpg";
                                    break;
                                }
                                case "Motivators": {
                                    avatar =
                                        "https://actioncoachassessments.com/UploadArea/269/badge5.jpg";
                                    break;
                                }
                                case "Sale IQ Plus": {
                                    avatar =
                                        "https://actioncoachassessments.com/UploadArea/269/badge1.jpg";
                                    break;
                                }
                                case "EIQ 2": {
                                    avatar =
                                        "https://actioncoachassessments.com/UploadArea/269/badge4.jpg";
                                    break;
                                }
                                case "Learning Styles": {
                                    avatar =
                                        "https://actioncoachassessments.com/UploadArea/269/badge2.jpg";
                                    break;
                                }
                                case "Kids DISC": {
                                    avatar =
                                        "https://www.assessments24x7.com/_nuxt/img/disc-kids-icon.b89fcd1.png";
                                    break;
                                }
                                default:
                                    break;
                            }
                            return (
                                <Space>
                                    <Tooltip title={entity.type}>
                                        <Avatar size={52} src={avatar} />
                                    </Tooltip>
                                    <div>
                                        <h4 style={{ margin: 0 }}>
                                            {isAdmin && entity.gender
                                                ? entity.gender + ". "
                                                : ""}
                                            {entity.firstname} {entity.lastname}
                                        </h4>
                                        <span style={{ margin: 0 }}>
                                            <Tooltip title="Đi tới bài đánh giá">
                                                <a
                                                    href={`/${entity.short}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {entity.type}{" "}
                                                    <LinkOutlined />
                                                </a>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </Space>
                            );
                        },
                    },
                    {
                        title: "Email",
                        sorter: (a, b) => a.email > b.email,
                        dataIndex: "email",
                        width: "250px",
                        render: (
                            _,
                            {
                                email,
                                emailCC,
                                type,
                                short,
                                gender,
                                firstname,
                                lastname,
                            }
                        ) => (
                            <>
                                <span
                                    style={{
                                        fontSize: "13px",
                                        color: "rgb(95, 99, 104)",
                                    }}
                                >
                                    {email}
                                </span>
                                {emailCC ? (
                                    <>
                                        <br />
                                        <small style={{ color: "#5f6368ad" }}>
                                            cc:{" "}
                                            {emailCC
                                                .replace(" ", "")
                                                .replace(",", ", ")}
                                        </small>
                                    </>
                                ) : (
                                    ""
                                )}
                                {isAdmin && (
                                    <div>
                                        <Popover
                                            content={
                                                <div
                                                    className="max-w-[400px]"
                                                    dangerouslySetInnerHTML={{
                                                        __html: emailUpdate(
                                                            type,
                                                            short,
                                                            gender,
                                                            firstname,
                                                            lastname
                                                        ),
                                                    }}
                                                />
                                            }
                                            title={
                                                <>
                                                    <strong>
                                                        Assessments 24x7 - Cập
                                                        nhật tháng 9/2024
                                                    </strong>
                                                </>
                                            }
                                        >
                                            <Button
                                                size="small"
                                                type="primary"
                                                onClick={() => {
                                                    handleSendmail({
                                                        email,
                                                        type,
                                                        short,
                                                        gender,
                                                        firstname,
                                                        lastname,
                                                    });
                                                }}
                                            >
                                                Gửi lại
                                            </Button>
                                        </Popover>
                                    </div>
                                )}
                            </>
                        ),
                    },
                    // isAdmin
                    //     ? {
                    //           title: "Danh xưng",
                    //           dataIndex: "gender",
                    //           render: (gender) => gender,
                    //       }
                    //     : { className: "hidden" },
                    {
                        title: "Phone",
                        dataIndex: "phone",
                        width: "150px",
                        render: (phone) => (
                            <span
                                style={{ fontSize: "13px", color: "#5f6368" }}
                            >
                                {phone}
                            </span>
                        ),
                    },
                    {
                        title: "Tags",
                        dataIndex: "tags",
                        align: "center",
                        width: "150px",
                        render: (_, { tags }) => (
                            <>
                                {Array.isArray(tags)
                                    ? tags.map((tag) => {
                                          return (
                                              <Tag
                                                  style={{ cursor: "pointer" }}
                                                  color="blue"
                                                  key={tag}
                                                  onClick={() =>
                                                      handleSelectTag(tag)
                                                  }
                                              >
                                                  {tag}
                                              </Tag>
                                          );
                                      })
                                    : ""}
                            </>
                        ),
                    },
                    {
                        title: "Ngày tạo",
                        dataIndex: "created",
                        sorter: (a, b) => a.created - b.created,
                        align: "center",
                        width: "140px",
                        render: (created) => {
                            return (
                                <h4
                                    style={{
                                        fontSize: "13px",
                                        color: "#5f6368",
                                        margin: 0,
                                    }}
                                >
                                    <Tooltip
                                        title={moment(created).format(
                                            "llll:ss"
                                        )}
                                    >
                                        {moment().diff(
                                            moment(created),
                                            "hours"
                                        ) >= 24
                                            ? moment(created).format("ll")
                                            : moment(created).fromNow()}{" "}
                                    </Tooltip>
                                </h4>
                            );
                        },
                    },
                    {
                        title: (
                            <Tooltip title="Hạn làm bài đánh giá">
                                Hết hạn
                            </Tooltip>
                        ),
                        dataIndex: "expired",
                        align: "center",
                        width: "140px",
                        render: (_, { created, status, _id, short }) => {
                            if (status === "Completed") {
                                return (
                                    <span
                                        style={{
                                            fontSize: "13px",
                                            color: "green",
                                        }}
                                    >
                                        Hoàn thành
                                    </span>
                                );
                            } else {
                                if (
                                    created &&
                                    moment(created)
                                        .add(30, "day")
                                        .diff(moment(), "days") < 0
                                ) {
                                    return (
                                        <>
                                            Hết hạn:{" "}
                                            {moment(created)
                                                .add(30, "day")
                                                .diff(moment(), "days")}
                                        </>
                                        // <Expired
                                        //     status={status}
                                        //     id={_id}
                                        //     short={short}
                                        // />
                                    );
                                } else {
                                    return (
                                        <h4
                                            style={{
                                                fontSize: "13px",
                                                color: "#1677ff",
                                                margin: 0,
                                            }}
                                        >
                                            Còn{" "}
                                            {moment(created)
                                                .add(30, "d")
                                                .diff(moment(), "days")}{" "}
                                            ngày
                                        </h4>
                                    );
                                }
                            }
                        },
                    },
                    {
                        title: "Kết quả",
                        dataIndex: "report",
                        align: "center",
                        width: "140px",
                        render: (_, entity) => (
                            <>
                                {entity.report &&
                                (entity.report.download || entity.download) ? (
                                    <>
                                        <h4>
                                            <a
                                                // href={
                                                //     entity.download ||
                                                //     (entity.type ==
                                                //         "Motivators" &&
                                                //     entity.language ==
                                                //         "Vietnamese" &&
                                                //     moment(
                                                //         entity.updated
                                                //     ).toDate() >
                                                //         new Date("2024-04-08")
                                                //         ? entity.report.download.replace(
                                                //               "https://actioncoachassessments.com/",
                                                //               "https://report.a247.co/mov/stream?url=https://actioncoachassessments.com/"
                                                //           )
                                                //         : entity.report
                                                //               .download)
                                                // }
                                                href={`/${entity.short}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Tải báo cáo{" "}
                                                <CloudDownloadOutlined />
                                            </a>{" "}
                                        </h4>
                                        {isAdmin && !entity.removed ? (
                                            <>
                                                {entity.report?.download &&
                                                !entity.download ? (
                                                    <ReFill entity={entity} />
                                                ) : null}
                                                {entity.download ? (
                                                    <Remove entity={entity} />
                                                ) : null}

                                                <Transfer entity={entity} />
                                            </>
                                        ) : null}
                                        {isAdmin && entity.removed ? (
                                            <Tag
                                                icon={<CheckCircleOutlined />}
                                                color="#108ee9"
                                            >
                                                Đã xóa
                                            </Tag>
                                        ) : null}
                                    </>
                                ) : (
                                    <Progress
                                        type="circle"
                                        percent={Math.floor(
                                            entity.report &&
                                                entity.report.current
                                                ? entity.report.current
                                                : 0
                                        )}
                                        size={50}
                                        style={{
                                            marginRight: 8,
                                        }}
                                    />
                                )}

                                {isAdmin ? <Move entity={entity} /> : null}
                            </>
                        ),
                    },
                    {
                        title: "Trạng thái",
                        align: "center",
                        width: "120px",
                        sorter: (a, b) => a.status > b.status,
                        dataIndex: "status",
                        render: (_, entity) => (
                            <>
                                <Tag
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        handleSelectStatus(entity.status)
                                    }
                                    color={statusColor(entity.status)}
                                >
                                    {entity.status == "Sented"
                                        ? "Sent"
                                        : entity.status}
                                </Tag>
                                {entity.status === "Created" ||
                                (entity.status === "Sented" &&
                                    moment().subtract(12, "h") <
                                        moment(entity.created)) ? (
                                    <Popconfirm
                                        title="Bạn muốn xoá?"
                                        onConfirm={() =>
                                            handleDelete(entity._id)
                                        }
                                    >
                                        <Button
                                            size="small"
                                            type="primary"
                                            danger
                                            icon={<DeleteOutlined />}
                                        />
                                    </Popconfirm>
                                ) : (
                                    ""
                                )}
                                <br />
                                <Tooltip
                                    title={moment(entity.updated).format(
                                        "llll:ss"
                                    )}
                                >
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            color: "#5f6368",
                                        }}
                                    >
                                        {moment().diff(
                                            moment(entity.updated),
                                            "hours"
                                        ) >= 24
                                            ? moment(entity.updated).format(
                                                  "ll"
                                              )
                                            : moment(entity.updated).fromNow()}
                                    </span>
                                </Tooltip>
                            </>
                        ),
                    },
                    isAdmin
                        ? {
                              title: "User",
                              dataIndex: "user",
                              sorter: (a, b) =>
                                  a.user.fullname > b.user.fullname,
                              render: (user, entity) =>
                                  user && (
                                      <>
                                          <Badge
                                              count={
                                                  user._credit != null
                                                      ? user._credit
                                                      : user.credit
                                              }
                                              size="small"
                                              offset={[2, -2]}
                                              overflowCount={9999}
                                          >
                                              <Tag
                                                  color="#108ee9"
                                                  style={{
                                                      fontSize: "12px",
                                                      cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                      handleUserChange([
                                                          user.userID,
                                                      ])
                                                  }
                                              >
                                                  {user.fullname}
                                              </Tag>
                                          </Badge>
                                          {entity.ip ? (
                                              <>
                                                  <br />
                                                  <small>{entity.ip}</small>
                                              </>
                                          ) : (
                                              ""
                                          )}
                                      </>
                                  ),
                          }
                        : { className: "hidden" },
                ]}
                dataSource={computeAssessments}
                pagination={{
                    total: computeAssessments.length,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
        </Card>
    );
}

export default Assessments;

// "%or": [
//     {
//       "%%user.id": "636b12b491a47d9f1e50d431"
//     },
//     {
//       "owner": "%%user.id"
//     }
//   ]
